footer {
    padding: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 15rem;
    color: white;
    background: var(--bg-color);
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 1200px;
}

footer a {
    color: #f6be00;
    display: inline-block;
    transition: all 0.1s linear;
}

footer a:hover {
    color: #ffc501;
}

.footer-img-wrapper {
    height: 2rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.footer-img {
    height: 100%;
}

@media all and (min-width: 576px) {
    section {
        flex-direction: row;
        justify-content: space-between;
    }

    .footer-img-wrapper {
        justify-content: flex-start;
    }
}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: 4rem;
    background: #171717;
    color: white;
}

.header-title {
    font-weight: bold;
    font-size: 1.5rem;
    text-decoration: none;
    color: white;
}

.header-accent {
    color: var(--accent-color-1);
}

nav {
    width: 1200px;
}

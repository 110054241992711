@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    animation: spin 0.30s linear infinite;
    width: 3.5rem;
    height: 3.5rem;
    border: 3px solid var(--accent-color-1);
    border-top-color: transparent;
    border-radius: 50%;
}

.spinner-wrapper {
    width: 3.5rem;
    height: 3.5rem;
    display: inline-block;
    overflow: hidden;
    background: none;
}
